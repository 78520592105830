import './App.css';
import { Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


function NavBar(props: { path: any; })
{

    ReactGA.send({ hitType: "pageview", page: props.path});

    return (
        <div id="nav">
            {props.path !== "/" &&
                <Link to="/">Home</Link>
            }
            {props.path === "/" &&
                <span>Home</span>
            }   

            {props.path !== "/ideaengine" &&
                <a href="/ideaengine">Details</a>
            }
            {props.path === "/ideaengine" &&
                <span>Details</span>
            }
            {props.path !== "/videos" &&
                <a href="/videos">Videos</a>
            }
            {props.path === "/videos" &&
                <span>Videos</span>
            }            
            {props.path !== "/contact" &&
                <a href="/contact">Contact X82 Ltd</a>
            }
            {props.path === "/contact" &&
                <span>Contact X82 Ltd</span>
            }

        </div>  
      
    );
}

export default NavBar;